<template>
  <div class="container-fliud">
    <Header></Header>
<div class="faqInfo">

  <h4 class="faq">Regster</h4>
   <hr>
<div class="gray">
   
        <div class="row" id="tweets">
          <form @submit.prevent="changepassword"> 
                <div class="heading">
                    <h1>Change Password</h1>
                </div>
                <div class="alert alert-danger" role="alert" v-if="msg">
                    {{msg}}
                </div>
                <div class="form-group">
                    <label for='password'>Old password</label>
                    <input type="password" class="form-control" v-model="oldpassword"   placeholder="Enter your  old password">
                     <span v-if="msg.oldpassword" style="color:red">{{msg.oldpassword}}</span>
                </div>
                <div class="form-group">
                    <label for="password">New password</label>
                    <input type="password" class="form-control" v-model="newpassword" placeholder="Enter new Password">
                     <span v-if="msg.newpassword" style="color:red">{{msg.newpassword}}</span>
                </div>
                
                <button type="submit" class="btn btn-primary form-control">Change password
                      <span class="spinner-border text-light" id="spinner" role="status" style="display:none; width:20px; height:20px; margin-left:20px">
                    </span>
                </button>
            </form>
        </div>
</div>

</div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl.js'
import $ from 'jquery'

export default {
  name: 'Login',
  components: {
    Header,
   Footer
  },
  data(){
      return{
          oldpassword:'',
          newpassword:'',
          msg:[]
      }
  },
  methods: {
      changepassword(){
           const passwordReg =/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.newpassword);
          //old password
           if(this.oldpassword ==""){
              this.msg['oldpassword']="Enter old password address";
              return false
          }else{
              this.msg['oldpassword']=""
          }
          //new password
           if(this.newpassword ==""){
              this.msg['newpassword']="Enter new password";
              return false
          }else if(!passwordReg){
              this.msg['newpassword'] = 'Password not strong enough' ;
                return false
          }else{
              this.msg['password']=""
          }
            $('#spinner').show();
          baseUrl.post('/api/user/changepassword',{
              oldpassword:this.oldpassword,
              newpassword:this.newpassword
          }).then(()=>{
              $('#spinner').hide();
              this.oldpassword="",
              this.newpassword=""
              this.$router.push('/') 
          }).catch((err)=>{
               $('#spinner').hide();
              this.msg = err.message
              this.oldpassword="",
              this.newpassword = ""
          })
      }
  },
}
</script>
<style scoped>
.faqInfo{
margin-top: 200px;
}
.gray{
background-color: rgb(255, 255, 255);
padding-bottom: 50px;
display:flex;
justify-content: center;
align-items: center;
}
form{
    border: 1px solid rgb(168, 165, 165);
    padding: 50px;
}
.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.faq{
padding-left: 100px;
}
.btn{
    background-color: #97c149;
    border: #97c149;
}
.login{
    color: #97c149;
}

</style>